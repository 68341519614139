<template>
  <v-container fluid class="px-10">
    <v-row>
      <v-col>
        <div class="d-flex">
          <h1 class="p-2 ml-4">Negocios Borradores</h1>
          <v-select
            label="Campaña"
            item-text="name"
            item-value="id"
            dense
            outlined
            clearable
            type="text"
            class="ml-4 mt-3"
            :items="abstractCampaignOptions"
            v-model="abstractCampaignIdFilter"
            @change="setCampaignQuery"
          ></v-select>
          <v-autocomplete
            label="Establecimiento"
            class="ml-4 mt-3"
            outlined
            dense
            v-model.number="newDraftEngagement.establishmentId"
            :items="establishments"
            item-text="name"
            item-value="id"
            @change="setEstablishmentQuery"
            :clearable="true"
          >
          </v-autocomplete>
          <v-select
            label="Estado de Attachment"
            class="ml-4 mt-3"
            outlined
            dense
            clearable
            v-model="attachmentStatusFilter"
            :items="attachmentStatusOptions"
            @change="setAttachmentStatusQuery"
          ></v-select>
        </div>
        <div class="d-flex ml-4 mr-4">
          <v-text-field
            class="mr-2"
            v-model="newDraftEngagement.draftName"
            type="text"
            dense
            outlined
            label="Nombre del Borrador"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            v-model.number="newDraftEngagement.physicalHectares"
            type="text"
            dense
            outlined
            label="Hectáreas físicas a asignar"
          ></v-text-field>
          <v-select
            label="Cultivo Pago Arrendamientos"
            class="mr-2"
            outlined
            dense
            readonly
            filled
            v-model="newDraftEngagement.rentQqActivity"
            :items="activities"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-text-field
            label="(0-100)% PVDT"
            dense
            outlined
            type="number"
            append-icon="mdi-percent-outline"
            class="mr-2"
            v-model.number="newDraftEngagement.variableParticipation"
          ></v-text-field>
          <v-text-field
            label="Fecha de PVDT"
            dense
            outlined
            type="date"
            class="mr-2"
            v-model="newDraftEngagement.variableParticipationDate"
          ></v-text-field>

          <v-text-field
            class="mr-2"
            v-model.number="newDraftEngagement.rentQqHa"
            type="number"
            dense
            outlined
            label="Alquiler (Qq/Ha)"
          ></v-text-field>

          <v-text-field
            class="mr-2"
            v-model.number="newDraftEngagement.rentQqActivityPrice"
            type="number"
            dense
            outlined
            label="Precio en adelanto"
          ></v-text-field>

          <v-btn
            color="green white--text"
            class="ml-1"
            @click="saveNewDraftEngagement"
            depressed
          >
            <v-icon> mdi-floppy </v-icon></v-btn
          >
        </div>
        <div class="d-flex">
          <v-btn
            small
            v-if="!isDraftEngagementCompareActive"
            color="blue white--text ml-4 mt-3 p-2"
            dark
            elevation="0"
            @click.stop="setDraftEngagementCompareActive"
          >
            <span class="pr-1">Comparar</span>
            <v-icon> mdi-swap-horizontal-bold </v-icon>
          </v-btn>
          <v-btn
            small
            color="orange darken-4 white--text ml-4 mt-3 p-2"
            dark
            elevation="0"
            @click="redirectToAttachmentsAnalysis"
          >
            <span>Ver Analisis de Attachments</span>
            <v-icon class="ml-2">mdi-file-table-box</v-icon>
          </v-btn>
          <v-btn
            small
            elevation="0"
            v-if="isDraftEngagementCompareActive"
            color="red white--text ml-4 mt-3 p-2"
            dark
            @click.stop="unSetDraftEngagementCompareActive"
          >
            <span class="pr-1">Cancelar</span>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="end">
        <v-data-table
          v-if="isDraftEngagementCompareActive"
          class="draft-engagement-btn"
          dense
          :headers="vDataTableHeaders"
          :items="draftEngagementsToCompare"
          show-select
          item-key="draftEngagementId"
          hide-default-footer
          v-model="selected"
          ><template v-slot:[`header.data-table-select`]></template
        ></v-data-table>
        <v-btn
          small
          elevation="0"
          v-if="isDraftEngagementCompareActive"
          color="blue white--text ml-4 my-3 p-2"
          :class="{ grey: draftEngagementsToCompare.length < 2 }"
          @click.stop="redirectToCompareDraftEngagements"
        >
          <span class="pr-1">Ir a Comparar</span>
          <v-icon> mdi-swap-horizontal-bold </v-icon>
        </v-btn>
        <v-data-table
          class="draft-engagement-btn"
          dense
          :headers="vDataTableHeaders"
          :items="draftEngagementItems"
          item-key="draftEngagementId"
          :show-select="
            isDraftEngagementCompareActive &&
            draftEngagementsToCompare.length < 2
          "
          v-model="selected"
          :items-per-page="15"
          @click:row="redirectToSingleDraftEngagement"
        >
          <template v-slot:[`header.data-table-select`]></template>
          <template v-slot:no-data>
            <div class="d-flex flex-column align-center py-6">
              <v-icon size="64" color="grey lighten-1">mdi-database-off</v-icon>
              <span class="grey--text text--darken-1 mt-2">
                No se encontraron borradores
              </span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.draft-engagement-btn:hover {
  cursor: pointer;
}
</style>
<script>
function convertDateString(dateStr) {
  const [day, month, year] = dateStr.split("/");
  // Month in JavaScript Date is zero-based so we subtract 1
  return new Date(year, month - 1, day);
}

// Function to compare two "DD/MM/YYYY" formatted dates
// Returns: 1 if first date is after second, -1 if before, 0 if equal
function compareDates(dateStr1, dateStr2) {
  const date1 = convertDateString(dateStr1);
  const date2 = convertDateString(dateStr2);

  if (date1.getTime() > date2.getTime()) {
    return 1;
  } else if (date1.getTime() < date2.getTime()) {
    return -1;
  }
  return 0;
}

import { mapGetters } from "vuex";
import { refreshToken } from "../managrx/AutAndAuthServices";
import { DRAFT_CONTRACTS, Soja1ActivityId } from "../managrx/constants";
import { postRequest } from "../managrx/serverCall";
export default {
  name: "DraftEngagements",
  props: {
    /* Prop from router */
    establishmentId: Number,
    abstractCampaignId: Number,
  },
  computed: {
    ...mapGetters([
      "abstractCampaigns",
      "draftEngagements",
      "establishments",
      "activities",
      "isDraftEngagementCompareActive",
      "draftEngagementsToCompare",
    ]),
    abstractCampaignOptions() {
      return [
        { id: 0, name: "Sin campaña asociada" },
        ...this.abstractCampaigns,
      ];
    },
    vDataTableHeaders() {
      return [
        { text: "Campaña asociada", value: "campaignName", class: "mx-10" },
        { text: "Nombre del Borrador", value: "draftName" },
        {
          text: "Region",
          value: "region",
        },
        {
          text: "Establecimiento",
          value: "establishmentName",
        },
        {
          text: "Has. Físicas",
          value: "totalHectares",
        },
        {
          text: "Proporción Ha. usadas",
          value: "usedhectaresRatio",
        },
        {
          text: "Creado",
          value: "createdAt",
          // Convert DD/MM/YYYY string to Date and then compare
          sort: (a, b) => compareDates(a, b),
        },
        {
          text: "Arrendamientos qq/ha",
          value: "rentQqHa",
        },
        {
          text: "Precio en adelanto Arrendamientos",
          value: "rentQqActivityPrice",
        },
        {
          text: "Attached?",
          value: "hasBeenAttached",
        },
        {
          text: "Attachments",
          value: "attachedEngagementsCount",
        },
      ];
    },
    isDraftEngagementsEmpty() {
      return this.draftEngagements.items.length === 0;
    },
    draftEngagementItems() {
      // Only format the data, no filtering
      return this.draftEngagements.items.map((d) => {
        return {
          ...d,
          campaignName: d.abstractCampaignId
            ? `${d.campaignName}`
            : "Sin campaña asociada",
          // make sure month is 0 padded
          createdAtIsoFormat: d.createdAt,
          createdAt: new Date(d.createdAt).toLocaleString("es-AR", {
            timeZone: "America/Argentina/Buenos_Aires",
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }),
        };
      });
    },
  },

  async mounted() {
    document.title = "Negocios Borradores";
    this.$store.dispatch("clearDraftEngagements");
    this.$store.dispatch("getAbstractCampaigns");
    await this.$store.dispatch("getEstablishments");

    // Parse URL parameters
    const query = this.$route.query;

    if (query.abstractCampaignId) {
      this.abstractCampaignIdFilter = parseInt(query.abstractCampaignId);
    }

    if (query.hasBeenAttached !== undefined) {
      // Set as string "true" or "false" to match our select options
      this.attachmentStatusFilter = query.hasBeenAttached;
    }

    if (query.establishmentId) {
      this.newDraftEngagement.establishmentId = parseInt(query.establishmentId);
      this.newDraftEngagement.physicalHectares = this.establishments?.find(
        (el) => el.id == query.establishmentId
      )?.hectares;
    }

    this.$store.dispatch("getActivities");

    // Build params object with all active filters
    const params = {
      ...(this.newDraftEngagement.establishmentId && {
        establishmentId: this.newDraftEngagement.establishmentId,
      }),
      ...(this.attachmentStatusFilter && {
        hasBeenAttached: this.attachmentStatusFilter,
      }),
      ...(this.abstractCampaignIdFilter !== null && {
        abstractCampaignId: this.abstractCampaignIdFilter,
      }),
    };

    // Single call to getDraftEngagements with all active filters
    await this.$store.dispatch("getDraftEngagements", params);

    this.selected = this.draftEngagementsToCompare;
  },
  methods: {
    redirectToAttachmentsAnalysis() {
      // conditionally send query params if they are defined
      let query = {};
      if (this.establishmentId) {
        query.establishmentId = this.establishmentId;
      }
      if (this.abstractCampaignId) {
        query.abstractCampaignId = this.abstractCampaignId;
      }
      this.$router.push({
        name: "DraftEngagementsAttachmentAnalysis",
        query,
      });
    },
    setDraftEngagementCompareActive() {
      this.$store.dispatch("setDraftEngagementCompareActive");
    },
    unSetDraftEngagementCompareActive() {
      this.$store.dispatch("setDraftEngagementsToCompare", []);
      this.selected = [];
      this.$store.dispatch("unSetDraftEngagementCompareActive");
    },
    redirectToCompareDraftEngagements() {
      if (this.draftEngagementsToCompare.length == 2) {
        let id1 = this.draftEngagementsToCompare[0]?.draftEngagementId;
        let id2 = this.draftEngagementsToCompare[1]?.draftEngagementId;
        this.$router.push({
          name: "DraftEngagementCompare",
          params: { id1: id1, id2: id2 },
        });
      }
    },
    redirectToSingleDraftEngagement(event) {
      if (!this.isDraftEngagementCompareActive) {
        this.$router.push({
          name: "SingleDraftEngagement",
          params: { id: event.draftEngagementId },
        });
      }
    },
    setEstablishmentQuery() {
      let establishmentId = this.newDraftEngagement.establishmentId;
      let query = {};

      // Always include all active filters in query
      if (establishmentId) {
        query.establishmentId = establishmentId;
      }

      if (this.abstractCampaignIdFilter !== null) {
        query.abstractCampaignId = this.abstractCampaignIdFilter;
      }

      if (this.attachmentStatusFilter !== null) {
        query.hasBeenAttached = this.attachmentStatusFilter;
      }

      // Update router query params
      this.$router.push({
        name: "DraftEngagements",
        query,
      });

      // Pass all active filters to getDraftEngagements
      this.$store.dispatch("getDraftEngagements", {
        establishmentId: this.newDraftEngagement.establishmentId,
        hasBeenAttached: this.attachmentStatusFilter,
        abstractCampaignId: this.abstractCampaignIdFilter,
      });
    },
    async saveNewDraftEngagement() {
      this.newDraftEngagement.variableParticipationDate = new Date(
        this.newDraftEngagement?.variableParticipationDate
      ).toISOString();
      // (0-100) to (0-1)
      this.newDraftEngagement.variableParticipation =
        this.newDraftEngagement?.variableParticipation / 100;

      let payload = {
        ...this.newDraftEngagement,
        ...(this.abstractCampaignIdFilter !== null && {
          abstractCampaignId: this.abstractCampaignIdFilter,
        }),
      };

      let response = await postRequest(`/draft_engagement`, payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        let tokenResponse = await refreshToken();
        if (tokenResponse.success) {
          let draftEngagementId = response.data;
          this.$router.push(`/draft-engagement/${draftEngagementId}`);
        } else {
          let notif = {
            success: false,
            data: {
              type: "alert",
              text: "Error al refrecar el Token, porfavor sal e inicia sesión nuevamente",
            },
          };
          this.$store.dispatch("setNewNotification", notif);
        }
      }
    },
    async setAttachmentStatusQuery() {
      console.log("========= setAttachmentStatusQuery START =========");
      console.log("Initial attachmentStatusFilter:", {
        value: this.attachmentStatusFilter,
        type: typeof this.attachmentStatusFilter,
      });

      let query = {};

      if (this.newDraftEngagement.establishmentId) {
        query.establishmentId = this.newDraftEngagement.establishmentId;
      }

      if (this.abstractCampaignIdFilter !== null) {
        query.abstractCampaignId = this.abstractCampaignIdFilter;
      }

      if (this.attachmentStatusFilter !== null) {
        query.hasBeenAttached = this.attachmentStatusFilter;
      }

      console.log("Query to be pushed to router:", query);

      try {
        // Wait for router update to complete
        await this.$router.push({
          name: "DraftEngagements",
          query,
        });

        const params = {
          establishmentId: this.newDraftEngagement.establishmentId,
          hasBeenAttached: this.attachmentStatusFilter,
          abstractCampaignId: this.abstractCampaignIdFilter,
        };

        console.log("Params being dispatched to store:", params);
        console.log("========= setAttachmentStatusQuery END =========");

        // Only dispatch after router update is complete
        await this.$store.dispatch("getDraftEngagements", params);
      } catch (err) {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
        // Still dispatch getDraftEngagements even if navigation fails
        const params = {
          establishmentId: this.newDraftEngagement.establishmentId,
          hasBeenAttached: this.attachmentStatusFilter,
          abstractCampaignId: this.abstractCampaignIdFilter,
        };
        await this.$store.dispatch("getDraftEngagements", params);
      }
    },
    setCampaignQuery() {
      let query = {};

      if (this.newDraftEngagement.establishmentId) {
        query.establishmentId = this.newDraftEngagement.establishmentId;
      }

      if (this.abstractCampaignIdFilter !== null) {
        query.abstractCampaignId = this.abstractCampaignIdFilter;
      }

      if (this.attachmentStatusFilter !== null) {
        query.hasBeenAttached = this.attachmentStatusFilter;
      }

      // Update router query params
      this.$router.push({
        name: "DraftEngagements",
        query,
      });

      // Pass params to getDraftEngagements
      this.$store.dispatch("getDraftEngagements", {
        establishmentId: this.newDraftEngagement.establishmentId,
        hasBeenAttached: this.attachmentStatusFilter,
        abstractCampaignId: this.abstractCampaignIdFilter,
      });
    },
  },
  data() {
    return {
      abstractCampaignIdFilter: null,
      attachmentStatusFilter: null,
      attachmentStatusOptions: [
        { text: "Attached", value: "true" },
        { text: "Not Attached", value: "false" },
      ],
      draftContracts: DRAFT_CONTRACTS,
      newDraftEngagement: {
        variableParticipation: 15,
        physicalHectares: null,
        rentQqActivity: Soja1ActivityId,
        materializationStatus: 4,
        draftName: "Borrador" + Math.floor(Date.now() / 10000).toString(),
        variableParticipationDate: new Date().toISOString().split("T")[0],
        contractType: null,
      },
      selected: [],
    };
  },
  watch: {
    selected(val, oldVal) {
      if (val.length > 2) {
        this.$nextTick(() => {
          this.selected = oldVal;
        });
      } else {
        this.$nextTick(() => {
          this.$store.dispatch("setDraftEngagementsToCompare", val);
        });
      }
    },
  },
};
</script>
